// Layout.scss

.main-content {
  min-height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0px;
  margin-top: 60px;
}

