// Nosotros.scss

@import "../variables.scss";


.generic-mision-vision,
.generic-integrantes, 
.generic-nosotros {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: -50px;
}

.generic-mision-vision {
  color: #FAFAFB;
  flex-direction: row;

  .title-mision-vision {
    align-items: center;
    justify-content: center;
  }

  .col-description {
    text-align: justify;
  }



}

.generic-mision-vision {
  overflow: hidden; /* Evita que el contenido se desborde */
}

.logo-image {
  max-width: 100%; /* Asegura que el logo no se desborde en pantallas pequeñas */
  height: auto;
}

@media (max-width: 576px) {
  .generic-mision-vision {
    padding: 10px; /* Reduce padding en pantallas pequeñas */

  }
  .title-mision-vision {
    margin-bottom: 20px;
    margin-top: 70px;
  }
  .logo-image {
    height: 150px; /* Tamaño más pequeño para el logo en dispositivos móviles */
  }
}


@media (min-width: 1100px) {
  .generic-mision-vision {
    padding: 50px 300px 0px 300px;
  }
}

.generic-integrantes {
  margin-top: 50px;
  background-color: #FAFAFB;
  // top right bottom left
  .title {
    font-size: 40px;
    margin-bottom: 50px;
  }

  .integrantes {
    
    .card-integrante {
      display: flex;
      align-items: center;

      margin-bottom: 20px;
      border-radius: 30px;
      padding: 30px;
      background-color: #0e1d34;
      color: white;


      .text-role {
        font-style: italic;
      }
    }
  }
  .linkedin-icon {
    margin-top: auto; /* Empuja el ícono hacia la parte inferior de la tarjeta */
  }


}
