// variables.scss


// Colors
$main-color: #4caf50;
$second-color: #5ec7a1;
$third-color: #ff8811;
$fourth-color: #F4D06F;

$card-integrante: #335c67;

// Fonts
$font-size-small: 16px;
$font-size-medium: 30px;
 
