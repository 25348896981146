.app {
    display: flex;
    padding: 5rem;
    justify-content: space-evenly;
    font-family: Verdana, Geneva, Tahoma, sans-serif
  }
  
  .map-container {
    height: 300px;
    width: 300px;
    border: 2px solid black;
  }
  