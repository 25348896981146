// MainNavbar.scss

@import "../variables.scss";

.main-navbar {

  background-color: rgba(14, 29, 52, 0.8);
  .container-navbar {
    margin-left: 10px;

    .right-text {
      font-size: 20px;
    }

    .navbar-brand, .navbar-nav .nav-link {
      color: white; 
    }

    .navbar-nav .nav-link:hover {
      color: $second-color;
    }
  }
}

.active-link {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}


@media (min-width: 630px) {
  .container-navbar {
    margin-left: 50px;
    display: flex;
    align-items: center;
  }

}

