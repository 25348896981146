// Footer.scss

@import "../variables.scss";


.footer {
  color: #FAFAFB;
  background-color: #0e1d34;

  .section {
    font-weight: bold;
    font-size: $font-size-medium;
  }

  .section-content {
    font-size: $font-size-small; 
  }

  .content {
    padding: 20px;
  }

  .stack-contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-square {
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 5px;
    }
  }
}

