// Home.scss

.home {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 100px;
  background-color: #F5F5F5;
}

$eduvidia-blue: #004daa;
$eduvidia-blue-transparent: #004DAACC;
$eduvidia-red: #A40E4C; 
$eduvidia-red-transparent: #A40E4CCC; 

.landing {
  width: 100%;

  .login-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;
    width: 100vw;

    margin: 0;

    background-image: linear-gradient($eduvidia-blue-transparent, $eduvidia-red-transparent), 
      url('../../public/students.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .row-logo {
      width: 60%;
      margin-bottom: 5%;
    }

    .row-buttons {
      width: 100%; /* Make it responsive */
      margin-top: 15%;
      .col-buttons {
        width: 100%; /* Full width on small screens */
        .buttons {
          width: 100%;
          border-radius: 20px;
        }
      }
    }

    /* Responsive adjustments */
    // @media (max-width: 768px) {
    //   .col-custom {
    //     width: 100%;
    //   }
    // }

    // @media (max-width: 576px) {
    //   .col-custom {
    //     width: 100%;
    //   }
    // }
  }

}