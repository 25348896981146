.generic-contacto {
  padding: 20px;
}

.contacto-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.contacto-subtitle {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 40px;
}

.social-media-links {
  display: flex;
  justify-content: space-around;
}

.social-media-link {
  text-decoration: none;
  color: #0077b5;
}

.social-media-name {
  font-size: 1rem;
  margin-top: 10px;
}

.map-container {
  width: 100%;
  height: 400px; /* Puedes ajustar la altura si es necesario */
}
