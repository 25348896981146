

.hero-video {
  width: 100%;
  
  height: auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: black;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: bolder;
}

.hero-img {
  max-width: 100%;
  height: auto;
}

.generic {
  background-color: #0e1d34;;
  color:  #FAFAFB;
}

.hero-container-generic {
  padding-left: 0px;
  border-right-width: 0px;
  padding-right: 0px;
}